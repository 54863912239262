import { AspectRatio, Button, Sheet, Card, CardActions, CardContent, CardOverflow, Divider, IconButton, Typography, Stack, Box, ButtonGroup, Link } from "@mui/joy";

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { LayoutContext } from "../../context";
import { AnimatePresence } from "framer-motion";
import ModalProduct from "../ModalProduct/ModalProduct";
import { Link as LinkRouter } from "react-router-dom";
import { API_URL, APIGetShopInfo } from "../../api";



export default function ItemProduct({ orientation, item }) {
    const { user, language, modalProductOpen, setModalProductOpen, setModalProduct, textTranslate } = useContext(LayoutContext);
    const { modalCompany, setModalCompany, setModalCompanyOpen } = useContext(LayoutContext);
    const { basket, addBasket, removeBasket } = useContext(LayoutContext);
    const [ count, setCount ] = useState(0);

    const [ orient, setOrient ] = useState('horizontal');

    useEffect(() => {
        setOrient(orientation);
    }, [orientation]);

    // При Mount, отслеживаем есть ли товар в корзине
    useEffect(() => {
        const bask_id = basket.findIndex((element) => element.product.id === item.id)
        if (bask_id > -1) setCount(basket[bask_id].count);
        else setCount(0);
    }, [basket, item]);


    const handle = () => {
        console.log('fff', item);
        setModalProduct({product: item, count: count, setCount: setCount});
        setModalProductOpen(true);
    };

    function toCompany() {
        APIGetShopInfo(item?.shop?.id, language).then((data) => {
            if (data) {
                setModalProductOpen(false);
                console.log('SHOP', data)
                setModalCompany(data);
                setModalCompanyOpen(true);
            }
        });
    }

    // Уменьшить товар в корзине
    function minusBask() {
        const rerer = removeBasket(count - 1, item);
        console.log(basket);        
        setCount(rerer);
    }
    // Увеличить товар в корзине
    function plusBask() {
        const rerer = addBasket(count + 1, setCount, item);
        console.log(basket);
        setCount(rerer);
    }

    // const translateDish = async () => {
    //     const text = "Hello";
    //     const sourceLang = "en";
    //     const targetLang = "ru";
    //     // Логика вызова API для перевода текста
    //     const response = await fetch(`https://lingva.ml/api/v1/${sourceLang}/${targetLang}/${encodeURIComponent(text)}`);
    //     const data = await response.json();
    //     console.log(data);
    //     console.log(data.translation);
    //     // return data.translatedText;
    // };

    return (
        <Card 
            variant="plain"
            size="sm"
            orientation={"horizontal"}
            sx={{
                p: 0,
                backgroundColor: 'white',
                boxShadow: 'none',
                transition: '.3s ease-in-out',
                transition: 'transform .3s ease-in-out',
                '&:hover': { 
                    transform: 'scale(1.03)', 
                    transition: 'transform .3s ease-in-out',
                    borderColor: 'neutral.outlinedHoverBorder' 
                }
            }}
        >
            <CardContent sx={{ p: 0, height: "100%" }}>
                <Stack
                    direction={ orient === 'horizontal' ? "row" : "column" }
                    spacing={0}
                    sx={{
                        height: '100%',
                        justifyContent: "space-between",
                        alignItems: "stretch",
                    }}
                >
                    <Box sx={{
                        minWidth: 130,
                        minHeight: 130,
                        height: orient == 'horizontal' ? 'auto' : '150px',
                        maxWidth: {
                            xs: orient == 'horizontal' ? "30vw" : 'auto'
                        },
                        borderRadius: 'xl',
                        overflow: "hidden",
                        p: 0
                    }}>
                        <img
                            src={ item?.photos_product?.length >= 1 ? item?.photos_product[0].photo : '/default.png' }
                            loading="lazy"
                            alt=""
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                            }}
                        />
                    </Box>
                    <Stack
                        direction="column"
                        spacing={0}
                        sx={{
                            width: orient == 'horizontal' ? "100%" : "auto",
                            p: 1.5,
                            height: 'auto',
                            justifyContent: "space-between",
                            alignItems: "stretch",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={0.5}
                            sx={{
                                height: '100%',
                                justifyContent: "flex-start",
                                alignItems: "stretch",
                            }}
                        >
                            <Typography level="title-lg" lineHeight={1.1} sx={{ fontWeight: 'md', cursor: 'pointer', textAlign: 'left' }} onClick={() => handle()}>
                                {item?.name}
                            </Typography>
                            <Typography level="body-sm" lineHeight={1.1} sx={{ color: "neutral.300", textAlign: 'left' }}>
                                {item?.description}
                            </Typography>
                            <Link color="primary" level="body-sm" onClick={() => toCompany() }>
                                {item?.shop?.store_name}
                            </Link>
                        </Stack>
                        <Stack
                            direction={ orient == 'horizontal' ? "row" : "column" }
                            spacing={1}
                            sx={{
                                // height: '100%',
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Typography level="title-lg" color="primary" sx={{ textAlign: 'left', width: "100%", height: 'auto', verticalAlign: '' }}>
                                {Math.ceil(item?.price)} රු
                            </Typography>
                        
                            {
                                count <= 0 ?
                                    <Button 
                                        size="sm"
                                        variant="soft"
                                        sx={{ fontSize: { xs: 'sm', sm: 'md' }, width: { xs: '100%', lg: 'auto' }, lineHeight: 1 }}
                                        onClick={plusBask}>
                                        {textTranslate.to_basket}
                                    </Button>
                                :
                                <Stack
                                    direction="row"
                                    spacing={0}
                                    sx={{
                                        width: orient == 'horizontal' ? "auto" : "100%",
                                        justifyContent: { xs: 'center', sm: 'flex-end' },
                                        alignItems: "stretch",
                                    }}
                                >
                                    <IconButton variant="soft" color="primary" size="sm" onClick={minusBask} sx={{ borderEndEndRadius: '0', borderTopRightRadius: '0', width: orient == 'horizontal' ? "auto" : "100%" }}>
                                        <RemoveRoundedIcon />
                                    </IconButton>
                                    <Sheet variant="soft" color="primary" size="sm"
                                        sx={(theme) => ({
                                            py: 0,
                                            fontSize: 'md',
                                            px: 1.5,
                                            textAlign: "center",
                                            height: 'auto',
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            width: orient == 'horizontal' ? "auto" : "100%",
                                            // backgroundColor: "primary.800",
                                            borderRadius: '0',
                                            [theme.breakpoints.down('sm')]: {
                                                // width: 'auto'
                                            }
                                        })}
                                    >
                                        {count}
                                    </Sheet>
                                    <IconButton variant="soft" color="primary" size="sm" onClick={plusBask} sx={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0', width: orient == 'horizontal' ? "auto" : "100%" }}>
                                        <AddRoundedIcon />
                                    </IconButton>
                                </Stack>
                            }
                        </Stack>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}