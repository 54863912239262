import { Box, Button, Container, Grid, Link, Stack, Typography } from "@mui/joy";
import Header from "../UI/header/Header";
import ChipSlider from "../components/ChipSlider/ChipSlider";
import SliderBanner from "../components/Banners/SliderBanner";
import ItemProduct from "../components/ItemProduct/ItemProduct";
import Category from "../components/Catagory/Category";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { LayoutContext } from "../context";
import { useContext, useEffect, useState } from "react";
import { APIGetProductsCategory, APIGetProductsGroup, APITypeGroupsByCategory } from "../api";


export default function CategoryPage() {
    const { slugID } = useParams(); // захватываем параметр из URL
    const { user, textTranslate, language } = useContext(LayoutContext);

    const [ typeGroups, setTypeGroups ] = useState([]);
    const [ slugGroup, setSlugGroup ] = useState(undefined);
    const [ products, setProducts ] = useState([]);


    function UpdateGroups() {
        APITypeGroupsByCategory(slugID, language).then((data) => {
            if (data) {
                console.log('GetTypeGroups', data)
                setTypeGroups(data);
            }
        });
    }
    function UpdateProductsCat() {
        APIGetProductsCategory(slugGroup ? slugGroup : null, language).then((data) => {
            if (data) {
                console.log('ADADAD', data)
                setProducts([{ group: slugGroup, data: data }])
            }
        })
    }
    useEffect(() => {
        UpdateGroups();
        UpdateProductsCat();
    }, []);


    function SlugListProduct(update) {
        // Если выбрана какая то группа
        if (slugGroup) {
            console.log("H2HH22HH2H2H");
            if (!update && products?.map((itm) => itm.group)?.includes(slugGroup)) return
            APIGetProductsGroup({ group_slug: slugGroup, limit: -1, lang: language }).then((data) => {
                if (data) {
                    console.log('RTRTRTRT', data)
                    if (update) setProducts([{ group: slugGroup, data: data }])
                    else setProducts([...products, { group: slugGroup, data: data }])
                }
            });
        }
        // Если все товары категории
        else {
            console.log("HHHHHHH", products);
            APIGetProductsCategory(null, language).then((data) => {
                if (data) {
                    console.log('ADADAD222', data)
                    if (update) setProducts([{ group: slugGroup, data: data }])
                    else setProducts([...products, { group: slugGroup, data: data }])
                }
            });
        }
    }
    useEffect(() => {
        console.log('QYYYY');
        UpdateGroups();
        SlugListProduct(true);
    }, [language]);
    useEffect(() => {
        console.log('QWERTY');
        SlugListProduct(false);
    }, [slugGroup]);
    


    return (
        <Box>
            <ChipSlider categoryID={slugID} slugGroup={slugGroup} setSlugGroup={setSlugGroup} typeGroups={typeGroups} />
            <Container maxWidth="sm" sx={{ my: 1 }}>
                <Typography level="h2" mb={2}>
                    { !slugGroup ?
                        textTranslate.products
                        :
                        typeGroups.find(group => group.tag_slug === slugGroup)?.name
                    }
                </Typography>
                { products?.find(obj => obj?.group === slugGroup)?.data?.length >= 1 ?
                    <Grid
                        container 
                        spacing={{ xs: 2 }}
                        columns={{ xs: 2, sm: 8, md: 10 }}
                        sx={{ flexGrow: 1 }}
                    >
                        {products?.find(obj => obj?.group === slugGroup)?.data?.map((product) => (
                            <Grid item xs={2} sm={4} md={5} key={product.id}>
                                <ItemProduct orientation={'horizontal'} item={product} />
                            </Grid>
                        ))}
                    </Grid>
                    :
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <img src='/shark_load.gif' height={150} style={{ borderRadius: '20px', objectFit: 'cover' }} />
                        <Typography sx={{ backgroundColor: 'neutral.50', py: 1, px: 1.5, borderRadius: 'md', textAlign: 'center' }}>
                            {textTranslate.loading} ...
                        </Typography>
                    </Stack>
                }
            </Container>
        </Box>
    )
}