import React, { useRef } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// import required modules
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';
import { Card, CardContent, CardCover, Chip, Container, Divider, Typography } from '@mui/joy';
import { API_URL } from '../../api';


export default function SliderPhoto({ photos }) {
    
    const banners = [
        { id: 1, title: 'Добро пожаловать!', text: 'Мы рады вас видеть в нашем боте', img: 'https://kazautocert.kz/wp-content/webpc-passthru.php?src=https://kazautocert.kz/wp-content/uploads/2021/09/04-09-2021-14-28-51-1024x629.jpg&nocache=1' },
        { id: 1, title: 'Закажите из ресторана', text: 'Теперь вы можете заказать прям из ресторана', img: 'https://salon.ru/storage/thumbs/gallery/525/524912/835_3500_s219.jpg' },
        { id: 1, title: 'Добро пожаловать!', text: 'Мы рады вас видеть в нашем боте', img: 'https://kazautocert.kz/wp-content/webpc-passthru.php?src=https://kazautocert.kz/wp-content/uploads/2021/09/04-09-2021-14-28-51-1024x629.jpg&nocache=1' }
    ]

    return (
        <Container maxWidth="sm" sx={{ my: 3 }}>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    stretch: 10,
                    slideShadows: false,
                }}
                autoplay={{
                    delay: 5000
                }}
                pagination={{
                    dynamicBullets: true,
                }}
                modules={[ EffectCoverflow, Pagination, Autoplay ]}
                className="SwiperCompanyPhoto"
                style={{ margin: '20px 0', width: '100%', borderRadius: '20px' }}
            >
                {
                    photos == [] ?
                        photos.map((item) => ( 
                            <SwiperSlide style={{ width: 'auto', borderRadius: '20px' }} key={item.id}>
                                <Card component="li" sx={{ width: '100%', maxWidth: 400, minHeight: 200, flexGrow: 1, borderRadius: '20px' }} variant="plain">
                                    <CardCover sx={{ borderRadius: '0' }}>
                                        <img
                                            src={API_URL + item?.photo}
                                            loading="lazy"
                                            alt=""
                                            style={{ objectFit: 'cover' }}
                                        />
                                    </CardCover>
                                    <CardCover
                                        sx={{ background: 'linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0) 300px)' }}
                                    />
                                </Card>
                            </SwiperSlide>
                        ))
                    :
                    <SwiperSlide style={{ width: 'auto', borderRadius: '20px' }}>
                        <Card component="li" sx={{ width: '100%', maxWidth: 400, minHeight: 200, flexGrow: 1, borderRadius: '20px' }} variant="plain">
                            <CardCover sx={{ borderRadius: '0' }}>
                                <img
                                    src={'/default.png'}
                                    loading="lazy"
                                    alt=""
                                    style={{ objectFit: 'cover' }}
                                />
                            </CardCover>
                            <CardCover
                                sx={{ background: 'linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0) 300px)' }}
                            />
                        </Card>
                    </SwiperSlide>
                }
            </Swiper>
        </Container>
    )
}