import { AnimatePresence, motion } from 'framer-motion';
import { Modal, Box, Button, Stack, Typography, IconButton, Input, Divider, Container, Grid } from '@mui/joy';
import { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../../context';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { AddBoxRounded } from '@mui/icons-material';
import InputPhone from '../InputPhone/InputPhone';
import SliderPhoto from './SliderPhoto';

import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import ItemProduct from '../ItemProduct/ItemProduct';
import SliderProducts from './SliderProducts';
import MapBlockCompany from './MapBlockCompany';


export default function ModalCompany() {
    const { modalCompany, modalCompanyOpen, setModalCompanyOpen } = useContext(LayoutContext);

    const [ name, setName ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ dateBirth, setDateBirth ] = useState('');
    
    useEffect(() => {
        if (modalCompanyOpen) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'auto';
        // Очистка при размонтировании компонента
        return () => document.body.style.overflow = 'auto';
    }, [modalCompanyOpen]);
    
    var products = [
        {id: 1, title: 'Помидор', img: 'https://www.medgorod.ru/sites/default/files/styles/gallery-slider-mobile/public/article_photo/produkty.jpg?itok=_T3D-Cnd', text: 'products'},
        {id: 2, title: 'Огурец', img: 'https://aif-s3.aif.ru/images/025/908/141ead4a45cadb2cfa4465242ba861e8.jpg', text: 'gotovaya-eda'},
        {id: 3, title: 'Котлета', img: 'https://avatars.mds.yandex.net/get-pdb/1881324/7e50dd30-89aa-41b6-a5d6-e51689038f5b/s1200?webp=false', text: 'tovari'}
    ]

    return (
        <AnimatePresence>
            {modalCompanyOpen && (
                <>
                    {/* Затемняющий фон */}
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backdropFilter: 'blur(10px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 10,
                        }}
                    />
                    <motion.div
                        initial={{ x: '100%', opacity: 0  }} // Начальное положение и прозрачность
                        animate={{ x: 0, opacity: 1 }} // Анимация открытия
                        // animate={modalCompany ? { scale: 0.9, y: 0, borderRadius: '25px' } : { y: 0, opacity: 1 }}
                        exit={{ x: 400, opacity: 0 }} // Анимация закрытия
                        transition={{ duration: 0.3, ease: "easeOut" }}
                        style={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: '100%',
                            backgroundColor: '#f3f3f3',
                            zIndex: 11,
                            overflow: 'auto',
                        }} // Длительность анимации
                    >
                        <Box sx={{ mb: 2 }}>
                            <Stack
                                direction='row'
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={2}
                                sx={{
                                    m: 2,
                                    my: 2, mb: 2
                                }}
                            >
                                <Typography level='h2' noWrap sx={{ mb: 2 }}>
                                    {modalCompany?.store_name}
                                </Typography>
                                <Stack
                                    direction='row'
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={{ xs: 1, sm: 2 }}
                                    sx={{
                                        my: 1,
                                    }}
                                >
                                    <IconButton variant="soft" color="danger" onClick={() => setModalCompanyOpen(false)} sx={{ height: 'fit-content' }}>
                                        <CloseRoundedIcon />
                                    </IconButton>
                                </Stack>
                            </Stack>

                            <Divider sx={{ m: 2 }}/>
                            <SliderPhoto photos={modalCompany?.photos} />
                            
                            <Container maxWidth="sm" sx={{ mt: 2 }}>
                                <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    spacing={2}
                                >   
                                    <Stack
                                        direction='row'
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={4}
                                    >
                                        
                                        <Stack
                                            sx={{ height: '100%', width: '100%' }}
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            spacing={0.5}
                                        >
                                            <Typography level='h3' lineHeight={1.1}>
                                                {modalCompany?.store_name}
                                            </Typography>
                                            <Box>
                                                <Stack
                                                    sx={{ height: '100%', width: '100%' }}
                                                    direction="row"
                                                    justifyContent="flex-end"
                                                    alignItems="center"
                                                    spacing={0}
                                                >
                                                    <StarRateRoundedIcon sx={(theme) => ({ color: theme.vars.palette.star[100] })}/>
                                                    <StarRateRoundedIcon sx={(theme) => ({ color: theme.vars.palette.star[100] })}/>
                                                    <StarRateRoundedIcon sx={(theme) => ({ color: theme.vars.palette.star[100] })}/>
                                                    <StarRateRoundedIcon sx={(theme) => ({ color: theme.vars.palette.star[100] })}/>
                                                    <StarRateRoundedIcon sx={(theme) => ({ color: theme.vars.palette.star[100] })}/>
                                                </Stack>
                                                <Typography level='body-xs'>
                                                    Оценка: 5.0
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                    <Typography level='body-sm' lineHeight={1.2}>
                                        {modalCompany?.description}
                                    </Typography>
                                    <MapBlockCompany latitude={modalCompany?.latitude} longitude={modalCompany?.longitude} />
                                    {/* <Grid
                                        container 
                                        spacing={{ xs: 2 }}
                                        columns={{ xs: 4, sm: 8, md: 10 }}
                                        sx={{ flexGrow: 1 }}
                                    >
                                        {products.map((product) => (
                                            <Grid item xs={2} sm={4} md={5} key={product.id}>
                                                <ItemProduct item={product} />
                                            </Grid>
                                        ))}
                                    </Grid> */}
                                </Stack>
                            </Container>

                            { modalCompany?.groups_product != [] ?
                                modalCompany?.groups_product?.map((group) => (
                                    <Stack
                                        direction="column"
                                        justifyContent="space-between"
                                        alignItems="stretch"
                                        spacing={0.1}
                                        sx={{ mt: 3, width: 'auto' }}
                                    >   
                                        <Typography level="h4" mb={2} sx={{ px: 2, width: 'auto', justifyContent: 'space-between' }}>
                                            {group?.name}
                                        </Typography>
                                        <SliderProducts products={group?.products} />
                                    </Stack>
                                ))
                                :
                                undefined
                            }
                        </Box>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    )
}