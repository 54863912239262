import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import axios from 'axios';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useTheme } from '@emotion/react';

// Импорт изображения маркера
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import markerShadowPng from 'leaflet/dist/images/marker-shadow.png';


export default function MapBlockCompany({ latitude, longitude }) {
    const [position, setPosition] = useState([latitude, longitude]); // Координаты Шри-Ланки, Коломбо
    const [address, setAddress] = useState("");

    // Настройка иконки маркера вручную
    const customIcon = new L.Icon({
        iconUrl: markerIconPng,
        shadowUrl: markerShadowPng,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    // Компонент для обработки кликов по карте
    // function LocationMarker() {
    //     useMapEvents({
    //     click: async (e) => {
    //         const { lat, lng } = e.latlng;
    //         setPosition([lat, lng]);

    //         // Выполнить обратное геокодирование для получения адреса
    //         try {
    //         const response = await axios.get(
    //             `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`
    //         );
    //         if (response.data && response.data.display_name) {
    //             setAddress(response.data.display_name);
    //         }
    //         } catch (error) {
    //         console.error("Ошибка при получении адреса:", error);
    //         }
    //     },
    //     });

    //     return position === null ? null : (
    //         <Marker position={position} icon={customIcon}></Marker>
    //     );
    // }

    return (
        <MapContainer
            center={position}
            zoom={15}
            style={{ height: '150px', width: '100%', borderRadius: '12px' }}
        >
            <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {/* <LocationMarker /> */}
            <Marker position={position} icon={customIcon}></Marker>
        </MapContainer>
    );
}