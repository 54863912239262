import { Avatar, Badge, Box, Button, Container, Divider, FormControl, IconButton, Input, Link, Stack, Typography } from "@mui/joy";
import SearchHeader from "../searchHeader/SearchHeader";
import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../../context";

import SentimentSatisfiedAltRoundedIcon from '@mui/icons-material/SentimentSatisfiedAltRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import LibraryAddCheckRoundedIcon from '@mui/icons-material/LibraryAddCheckRounded';


export default function Header({ pathBag }) {
    const { setModalAccountOpen } = useContext(LayoutContext);
    const { setModalBasketOpen } = useContext(LayoutContext);
    const { setModalOrders } = useContext(LayoutContext);
    const { basket, addBasket, removeBasket } = useContext(LayoutContext);

    const [ countBasket, setCountBasket ] = useState(undefined);
    useEffect(() => {
        setCountBasket(basket?.length)
    }, [basket])

    return (
        <header>
            <Container maxWidth="sm" sx={{ py: 2 }}>
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        justifyContent: "center",
                        alignItems: "stretch"
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            <img
                                width={40}
                                src="/logo.png"
                                loading="lazy"
                                alt=""
                            />
                            <Typography level="h3" fontWeight="bold" fontFamily={'NordiquePro'} sx={{ pb: 1 }}>
                                biteride
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        >
                            {/* <Badge badgeContent={1} size="sm" color="success" variant="solid">
                                <IconButton size="md" onClick={() => setModalOrders(true)}>
                                    <LibraryAddCheckRoundedIcon />
                                </IconButton>
                            </Badge> */}
                            <Badge badgeContent={countBasket} size="sm" variant="soft">
                                <IconButton size="md" onClick={() => countBasket ? setModalBasketOpen(true) : undefined}>
                                    <ShoppingCartRoundedIcon />
                                </IconButton>
                            </Badge>
                            <Avatar 
                                alt="Кирилл" 
                                src="/smile.png" 
                                size="md" 
                                onClick={() => setModalAccountOpen(true)}
                                sx={{
                                    cursor: 'pointer',
                                    "& img": {
                                        height: '25px',
                                        width: '25px'
                                    }
                                }}/>
                        </Stack>
                    </Box>

                    <SearchHeader pathBag={pathBag}/>
                </Stack>
            </Container>
            <Divider />
        </header>
    )
}