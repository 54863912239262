import { Box, Container, Grid, Typography } from "@mui/joy";
import Header from "../UI/header/Header";
import ChipSlider from "../components/ChipSlider/ChipSlider";
import SliderBanner from "../components/Banners/SliderBanner";
import ItemProduct from "../components/ItemProduct/ItemProduct";
import Category from "../components/Catagory/Category";
import { Link as LinkRouter, useLocation } from "react-router-dom";
import SearchHeader from "../UI/searchHeader/SearchHeader";
import ModalProduct from "../components/ModalProduct/ModalProduct";

import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { useContext, useEffect, useMemo } from "react";
import { LayoutContext } from "../context";


export default function MainPage() {
    const { textTranslate } = useContext(LayoutContext);

    var products = [
        {id: 1, title: 'Помидор', img: 'https://www.medgorod.ru/sites/default/files/styles/gallery-slider-mobile/public/article_photo/produkty.jpg?itok=_T3D-Cnd', text: 'products'},
        {id: 2, title: 'Огурец', img: 'https://aif-s3.aif.ru/images/025/908/141ead4a45cadb2cfa4465242ba861e8.jpg', text: 'gotovaya-eda'},
        {id: 3, title: 'Котлета', img: 'https://avatars.mds.yandex.net/get-pdb/1881324/7e50dd30-89aa-41b6-a5d6-e51689038f5b/s1200?webp=false', text: 'tovari'}
    ]
    
    return (
        <Box sx={{ mb: 4 }}>
            <SliderBanner/>
            <Container maxWidth="sm" sx={{ my: 3 }}>
                <Typography level="h3" mb={2}>
                    {textTranslate.categories}
                </Typography>
                
                <Category/>
            </Container>

            {/* <Container maxWidth="sm" sx={{ my: 2 }}>
                <LinkRouter to={`/category/pp`} style={{ textDecoration: 'none', width: '100%' }}>
                    <Typography level="h4" mb={2} endDecorator={<KeyboardArrowRightRoundedIcon/>} sx={{ width: '100%', justifyContent: 'space-between' }}>
                        Популярное
                    </Typography>
                </LinkRouter>
                <Grid
                    container 
                    spacing={{ xs: 2 }}
                    columns={{ xs: 4, sm: 8, md: 10 }}
                    sx={{ flexGrow: 1 }}
                >
                    {products.map((product) => (
                        <Grid item xs={2} sm={4} md={5} key={product.id}>
                            <ItemProduct item={product} />
                        </Grid>
                    ))}
                </Grid>
            </Container> */}
            
        </Box>
    )
}