import { Card, CardContent, CardCover, Container, CardOverflow, Grid, Typography, Stack } from "@mui/joy";
import CategoryItem from "./CategoryItem";
import { useContext, useEffect, useState } from "react";
import { APIGetCategories } from "../../api";
import { LayoutContext } from "../../context";




export default function Category() {
    const { user, textTranslate, language } = useContext(LayoutContext);
    const [ listCategory, setListCategory ] = useState([]);
    

    function GetCat() {
        APIGetCategories(language).then((data) => {
            if (data) {
                console.log('CATALOG', data);
                setListCategory(data)
            }
        })
    }

    useEffect(() => {
        GetCat();
    }, []);
    useEffect(() => {
        GetCat();
    }, [language]);

    return (
        <Grid 
            container 
            spacing={{ xs: 2 }}
            columns={{ xs: 4, sm: 8, md: 10 }}
            sx={{ flexGrow: 1 }}
        >
            {  listCategory == [] ?
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                >
                    <img src='/shark_load.gif' height={150} style={{ borderRadius: '20px', objectFit: 'cover' }} />
                    <Typography sx={{ backgroundColor: 'neutral.50', py: 1, px: 1.5, borderRadius: 'md', textAlign: 'center' }}>
                        {textTranslate.loading} ...
                    </Typography>
                </Stack> 
                :
                listCategory.map((category) => (
                    <Grid item xs={2} sm={4} md={5} key={category.id}>
                        <CategoryItem item={category}/>
                    </Grid>
                ))
            }
        </Grid>
    )
}