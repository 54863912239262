import { AnimatePresence, motion } from 'framer-motion';
import { Modal, Box, Button, Stack, Typography, IconButton, Input, Divider, Checkbox, FormHelperText, Link, Textarea, ListItem, List, ListItemDecorator, Sheet, ListItemContent, Switch, ListDivider, ListItemButton, switchClasses, sheetClasses, FormControl } from '@mui/joy';
import { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../../context';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { AddBoxRounded } from '@mui/icons-material';
import InputPhone from '../InputPhone/InputPhone';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { Link as LinkRouter } from 'react-router-dom';
import MapBlock from './MapBlock';

import PinDropRoundedIcon from '@mui/icons-material/PinDropRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { APICreateOrder } from '../../api';
import { useTheme } from '@emotion/react';


export default function ModalOrderCreate({ isOpen, onClose }) {
    const theme = useTheme();
    const { modalBasket, setModalBasketOpen } = useContext(LayoutContext);
    const { modalOrderCreate, setModalOrderCreate, basket, setBasket, user, webApp } = useContext(LayoutContext);

    const { textTranslate } = useContext(LayoutContext);

    const [ position, setPosition ] = useState([6.1393, 80.1058]); // Координаты Шри-Ланки, Коломбо
    const [ btnLoading, setBtnLoading ] = useState(false);
    const [ name, setName ] = useState('');
    // const [  ]
    // const [ phone, setPhone ] = useState('');
    const [ comment, setComment ] = useState('');

    function home(){
        setModalOrderCreate(false);
        setModalBasketOpen(false);
    }

    function orderApiCreate(event) {
        event.preventDefault();
        const items = basket?.map((item) => {return {product_id: item.product.id, count: item.count}})

        setBtnLoading(true);
        const _data = {
            "token": user?.token,
            "address_delivery": {
                "latitude": position[0]?.toFixed(6),
                "longitude": position[1]?.toFixed(6)
            },
            "comment": comment,
            "items": items
          }

        APICreateOrder(_data).then((data) => {
            if (data) {
                console.log('Create ORDER', data)
                if (data?.id) {
                    webApp?.HapticFeedback?.notificationOccurred('success');
                    const orderDetails = { type: 'create_order', id: data?.id };
                    // Отправляем данные о заказе в бота
                    webApp?.sendData(JSON.stringify(orderDetails)); // Отправляем данные в формате JSON
                    // Закрываем WebApp
                    webApp?.close();
                    
                    setBtnLoading(false);
                    setBasket([]);
                    home();
                    return
                }
                return 
            }
        });
    }

    const [ totalPrice, setTotalPrice ] = useState(0);
    useEffect(() => {
        var sum = 0;
        basket?.map((item) => (
            sum += item.product.price * item.count
        ))
        setTotalPrice(sum);
        if (basket?.length <= 0) setModalBasketOpen(false);
    }, [basket])
    
    useEffect(() => {
        if (modalBasket | modalOrderCreate) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'auto';
        // Очистка при размонтировании компонента
        return () => document.body.style.overflow = 'auto';
    }, [modalBasket, modalOrderCreate]);
    
    return (
        <AnimatePresence>
            {modalOrderCreate && (
                <>
                    {/* Затемняющий фон */}
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backdropFilter: 'blur(10px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 10,
                        }}
                    />
                    <motion.div
                        initial={{ x: '100%', opacity: 0  }} // Начальное положение и прозрачность
                        animate={{ x: 0, opacity: 1 }} // Анимация открытия
                        exit={{ x: 400, opacity: 0 }} // Анимация закрытия
                        transition={{ duration: 0.3, ease: "easeOut" }}
                        style={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            height: '100%',
                            backgroundColor: '#f3f3f3',
                            zIndex: 11,
                            overflow: 'auto',
                          }} // Длительность анимации
                    >
                        <form onSubmit={orderApiCreate}
                            style={{
                                margin: theme.spacing(2),
                                marginBottom: theme.spacing(4),
                            }}
                        >
                            <Stack
                                direction='row'
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={{ xs: 1, sm: 2 }}
                                sx={{
                                    mb: 2
                                }}
                            >
                                <Typography level='h2'>
                                    {textTranslate.processing}
                                </Typography>
                                <IconButton variant="soft" color="danger" onClick={() => home()} sx={{ height: 'fit-content' }}>
                                    <HomeRoundedIcon />
                                </IconButton>
                            </Stack>
                            <Divider/>

                            <Button sx={{ mt: 2, width: 'fit-content' }} startDecorator={<ArrowBackIosRoundedIcon/>} color="primary" variant="plain" onClick={() => setModalOrderCreate(false)}>
                                {textTranslate.basket}
                            </Button>

                            <Stack
                                justifyContent="space-between"
                                alignItems="stretch"
                                spacing={{ xs: 1, sm: 2 }}
                                direction={{ xs: 'column', sm: 'row' }}
                                sx={(theme) => ({
                                    p: 1,
                                    mb: 1,
                                })}
                            >   
                                <Stack
                                    direction='column'
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Box sx={{ width: '100%' }}>
                                        <Typography sx={{ mb: 0 }} level='title-md' fontWeight={'xl'}>
                                            {textTranslate.address_delivery}
                                        </Typography>
                                        <Typography sx={{ mb: 0.5 }} level='body-xs' color='primary'>
                                            {textTranslate.select_point_map}
                                        </Typography>
                                        <MapBlock position={position} setPosition={setPosition} />
                                        {/* <Input type="text" placeholder="Луначарского, 99" variant="soft" sx={{ mt: 2 }} /> */}
                                        
                                        {/* <List
                                            aria-labelledby="ios-example-demo"
                                            variant='outlined'
                                            sx={(theme) => ({
                                                mt: 2,
                                                borderColor: 'background.level1',
                                                '& ul': {
                                                    '--List-gap': '0px',
                                                    '& > li:first-child > [role="button"]': {
                                                        borderTopRightRadius: 'var(--List-radius)',
                                                        borderTopLeftRadius: 'var(--List-radius)',
                                                    },
                                                    '& > li:last-child > [role="button"]': {
                                                        borderBottomRightRadius: 'var(--List-radius)',
                                                        borderBottomLeftRadius: 'var(--List-radius)',
                                                    },
                                                },
                                                '--List-radius': theme.radius.sm,
                                                '--List-gap': '1rem',
                                                '--ListDivider-gap': '0px',
                                                '--ListItem-paddingY': '0.5rem',

                                                '--joy-palette-neutral-plainHoverBg': 'rgba(0 0 0 / 0.08)',
                                                '--joy-palette-neutral-plainActiveBg': 'rgba(0 0 0 / 0.12)',
                                                [theme.getColorSchemeSelector('light')]: {
                                                    '--joy-palette-divider': 'rgba(0 0 0 / 0.08)',
                                                },
                                                [theme.getColorSchemeSelector('dark')]: {
                                                    '--joy-palette-neutral-plainHoverBg': 'rgba(255 255 255 / 0.1)',
                                                    '--joy-palette-neutral-plainActiveBg': 'rgba(255 255 255 / 0.16)',
                                                },
                                            })}
                                        >
                                            <ListItem nested>
                                                <List
                                                    aria-label="Network"
                                                    sx={{
                                                        [`& .${sheetClasses.root}`]: {
                                                            p: 0.8,
                                                            lineHeight: 0,
                                                            borderRadius: 'sm',
                                                        },
                                                    }}
                                                >
                                                    <ListItem>
                                                        <ListItemButton>
                                                            <ListItemDecorator>
                                                            <Sheet variant="solid" color="primary">
                                                                <PinDropRoundedIcon />
                                                            </Sheet>
                                                            </ListItemDecorator>
                                                            <ListItemContent sx={{ fontSize: 'sm' }}>
                                                                Луначарского, 99
                                                            </ListItemContent>
                                                            <Button
                                                                color='danger'
                                                                sx={{ px: 1 }}
                                                                size='sm' 
                                                                variant="soft"
                                                                onClick={() => alert("DEL")}
                                                            >
                                                                <DeleteOutlineRoundedIcon />
                                                            </Button>
                                                            <ArrowForwardIosRoundedIcon fontSize="xl1" />
                                                        </ListItemButton>
                                                    </ListItem>
                                                    <ListDivider inset="startContent" />
                                                    <ListItem>
                                                        <ListItemButton>
                                                            <ListItemDecorator>
                                                            <Sheet variant="solid" color="primary">
                                                                <PinDropRoundedIcon />
                                                            </Sheet>
                                                            </ListItemDecorator>
                                                            <ListItemContent sx={{ fontSize: 'sm' }}>
                                                                Луначарского, 99
                                                            </ListItemContent>
                                                            <Button
                                                                color='danger'
                                                                sx={{ px: 1 }}
                                                                size='sm' 
                                                                variant="soft"
                                                                onClick={() => alert("DEL")}
                                                            >
                                                                <DeleteOutlineRoundedIcon />
                                                            </Button>
                                                            <ArrowForwardIosRoundedIcon fontSize="xl1" />
                                                        </ListItemButton>
                                                    </ListItem>
                                                    <ListDivider inset="startContent" />
                                                    <ListItem>
                                                        <ListItemButton>
                                                            <ListItemDecorator>
                                                            <Sheet variant="solid" color="primary">
                                                                <PinDropRoundedIcon />
                                                            </Sheet>
                                                            </ListItemDecorator>
                                                            <ListItemContent sx={{ fontSize: 'sm' }}>
                                                                Луначарского, 99
                                                            </ListItemContent>
                                                            <Button
                                                                color='danger'
                                                                sx={{ px: 1 }}
                                                                size='sm' 
                                                                variant="soft"
                                                                onClick={() => alert("DEL")}
                                                            >
                                                                <DeleteOutlineRoundedIcon />
                                                            </Button>
                                                            <ArrowForwardIosRoundedIcon fontSize="xl1" />
                                                        </ListItemButton>
                                                    </ListItem>
                                                </List>
                                            </ListItem>
                                        </List> */}
                                    </Box>
                                    <Box sx={{ width: '100%' }}>
                                        <Typography sx={{ mb: 0.5 }} level='title-md' fontWeight={'xl'}>
                                            {textTranslate.comment_order}
                                        </Typography>
                                        <Textarea minRows={3} variant="soft" sx={{ backgroundColor: "white", boxShadow: "none" }} value={comment} onChange={(e) => setComment(e.target.value)} placeholder={textTranslate.no_comment} />
                                    </Box>

                                    <Box sx={{ width: '100%' }}>
                                        <Typography sx={{ mb: 0.5 }} level='title-md' fontWeight={'xl'}>
                                            {textTranslate.name}*
                                        </Typography>
                                        <Input required type="text" sx={{ backgroundColor: "white", boxShadow: "none" }} onChange={(e) => setName(e.target.value)} value={name} placeholder={textTranslate.ivan} variant="soft" />
                                    </Box>
                                    {/* <Box sx={{ width: '100%' }}>
                                        <Typography sx={{ mb: 0.5 }} level='title-md' fontWeight={'xl'}>
                                            Номер телефона*
                                        </Typography>
                                        <InputPhone phone={phone} setPhone={setPhone}/>
                                    </Box> */}

                                    <Box sx={{ width: '100%' }}>
                                        <Checkbox
                                            required
                                            label={
                                            <Box>
                                                <Typography level="body-sm" lineHeight={1}>{textTranslate.policy_check}</Typography>
                                            </Box>
                                            }
                                        />
                                        <FormHelperText sx={{ my: 0 }}>
                                            <Typography level="body-xs">
                                                {textTranslate.read} <LinkRouter to="/policy"><Link color='primary'>{textTranslate.policy_link}</Link></LinkRouter>.
                                            </Typography>
                                        </FormHelperText>
                                    </Box>
                                </Stack>
                            </Stack>
                            
                            <Box
                                sx={(theme) => ({
                                    p: 1,
                                    // boxShadow: 'md',
                                    backdropFilter: 'blur(8px)',
                                    backgroundColor: '#ffffff59',
                                    position: 'sticky', bottom: 30,
                                    borderRadius: 'md',
                                })}
                            >
                                <Stack
                                    justifyContent="space-between"
                                    alignItems="stretch"
                                    spacing={{ xs: 1, sm: 2 }}
                                    direction={{ xs: 'column', sm: 'row' }}
                                    sx={(theme) => ({
                                        // p: 1,
                                        mb: 1
                                        // boxShadow: 'md',
                                        // backdropFilter: 'blur(10px)',
                                        // backgroundColor: theme.vars.palette.neutral[50],
                                        // position: 'sticky', bottom: 10,
                                        // borderRadius: 'md',
                                    })}
                                >   
                                    <Stack
                                        direction='row'
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={4}
                                    >
                                        
                                        <Stack
                                            sx={{ height: '100%', width: '100%' }}
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="flex-start"
                                            spacing={0.5}
                                        >
                                            <Typography level='h3'>
                                                {textTranslate.conclusion}:
                                            </Typography>
                                            <Typography fontWeight="xl" level="h3">
                                                {Math.ceil(totalPrice)} රු
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>

                                <Button 
                                    color='primary'
                                    size="lg"
                                    type='submit'
                                    loading={btnLoading}
                                    // onClick={orderApiCreate}
                                    sx={{
                                        height: 40,
                                        fontSize: 'lg',
                                        width: { xs: '100%', lg: 'auto' }
                                    }}
                                >
                                    {textTranslate.submit}
                                </Button>
                            </Box>
                        </form>
                    </motion.div>
                </>
            )}
        </AnimatePresence>
    )
}