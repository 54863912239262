import { FormControl, IconButton, Input, Stack } from "@mui/joy";
import { useLocation, useNavigate } from "react-router-dom";

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { LayoutContext } from "../../context";
import { useContext } from "react";


export default function SearchHeader({ pathBag }) {
    const { textTranslate } = useContext(LayoutContext);
    const location = useLocation();
    const navigate = useNavigate();

    // Проверка, на главной ли странице
    const isHomePage = location.pathname !== '/';
    console.log(pathBag);
    return (
        <FormControl>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    width: '100%',
                    justifyContent: "flex-start",
                    alignItems: "center",
                }}
            >
                { isHomePage ?
                    <IconButton color="primary" level="title-lg" underline="none" onClick={() => navigate(pathBag)}>
                        <ArrowBackIosRoundedIcon/>
                    </IconButton>
                    :
                    undefined
                }
                <Input
                    color="neutral" variant="plain"
                    placeholder={textTranslate.search}
                    endDecorator={<SearchRoundedIcon />}
                    sx={{
                        backgroundColor: 'white',
                        width: '100%',
                        // '--Input-focusedInset': 'var(--any, )',
                        // '--Input-focusedHighlight': 'rgba(13,110,253,.25)',
                        // '&::before': {
                        //     transition: 'box-shadow .15s ease-in-out',
                        // },
                        // '&:focus-within': {
                        //     borderColor: '#86b7fe',
                        // },
                    }}
                />
            </Stack>
        </FormControl>
    )
}