

export const LINK_TRANSLATE = "http://localhost:5000";


export const textStock = {
  lang_load: 'Loading a new language...'
}


// -=-=-=- Шаблоны текстов -=-=-=-
const texts = {
    ru: {
      search: 'Поиск',
      category: 'Категория',
      categories: 'Категории',
      products: 'Товары',
      account: 'Профиль',
      name: 'Имя',
      ivan: 'Иван',
      phone: 'Номер телефона',
      date_birthday: 'Дата рождения',
      language: 'Язык',
      save: 'Сохранить',
      price: 'Стоимость',
      design: 'Design',
      summary: 'Итог',
      currency: {
        ru: '₽',
        si: '₹',
        en: '₹'
      },
      basket: 'Корзина',
      to_basket: 'В корзину',
      conclusion: 'Итог',
      address: 'Адрес',
      address_delivery: 'Адрес доставки',
      select_point_map: 'Выберите точку на карте',
      comment_order: 'Коментарий к заказу',
      no_comment: 'Без комментариев',
      policy_check: 'Я соглашаюсь с политикой ресурса BiteRide',
      read: 'Читать',
      policy_link: 'пользовательское соглашение',
      order: 'Заказ',
      submit: 'Заказать',
      orders: 'Заказы',
      processing: 'Оформление',
      all: 'Все',
      delivered: 'Доставляется',
      loading: 'Загрузка',
      list_none: 'Тут ничего нет',
      name_none: 'Вы не ввели имя',
    },
    en: {
      search: 'Search',
      category: 'Category',
      categories: 'Categories',
      products: 'Goods',
      account: 'Profile',
      name: 'Name',
      ivan: 'Ivan',
      phone: 'Phone number',
      date_birthday: 'Date Birthday',
      language: 'Language',
      save: 'Save',
      price: 'Price',
      design: 'Design',
      summary: 'Summary',
      currency: {
        ru: '₽',
        si: '₹',
        en: '₹'
      },
      basket: 'Basket',
      to_basket: 'Add to basket',
      conclusion: 'Conclusion',
      address: 'Address',
      address_delivery: 'Address delivery',
      select_point_map: 'Select a point on the map',
      comment_order: 'Comment to order',
      no_comment: 'No comment',
      policy_check: 'I agree with the BiteRide policy',
      read: 'Read',
      policy_link: 'User Agreement',
      order: 'Order',
      submit: 'Place an order',
      orders: 'Orders',
      processing: 'Processing',
      all: 'All',
      delivered: 'Delivered',
      loading: 'Loading',
      list_none: 'There is nothing here',
      name_none: 'You did not enter a name',
    },
    si: {
      search: 'සොයන්න',
      category: 'ප්රවර්ගය',
      categories: 'වර්ග',
      products: 'භාණ්ඩ',
      account: 'පැතිකඩ',
      name: 'නම',
      ivan: 'අයිවන්',
      phone: 'දුරකථන අංකය',
      date_birthday: 'දිනය උපන් දිනය',
      language: 'භාෂාව',
      save: 'සුරකින්න',
      price: 'මිල',
      design: 'Design',
      summary: 'සාරාංශය',
      currency: {
        ru: '₽',
        si: '₹',
        en: '₹'
      },
      basket: 'කූඩය',
      to_basket: 'බැස්කට්ට',
      conclusion: 'පහළ රේඛාව',
      address: 'ලිපිනය',
      address_delivery: 'ලිපින බෙදාහැරීම',
      select_point_map: 'සිතියමෙන් ලක්ෂ්‍යයක් තෝරන්න',
      comment_order: 'ඇණවුම් කිරීමට අදහස් දක්වන්න',
      no_comment: 'ප්රතිචාරයක් නැත',
      policy_check: 'මම BiteRide ප්‍රතිපත්තියට එකඟ වේ',
      read: 'කියවන්න',
      policy_link: 'පරිශීලක ගිවිසුම',
      order: 'ඇණවුම් කරන්න',
      submit: 'ඇනවුමක් කරන්න',
      orders: 'නියෝග',
      processing: 'ප්‍ර ක්‍රියා කරමින්',
      all: 'සියල්ල',
      delivered: 'භාර දුන්නා',
      loading: 'පැටවීම',
      list_none: 'මෙතන කිසිවක් නැහැ',
      name_none: 'ඔබ නමක් ඇතුලත් කර නැත',
    },

    // basket: {
    //   contact: 'Contact Us',
    //   rights: 'All rights reserved.',
    // },
    // buttonLabels: {
    //   submit: 'Submit',
    //   cancel: 'Cancel',
    //   save: 'Save Changes',
    // },
    // errors: {
    //   requiredField: 'This field is required.',
    //   invalidEmail: 'Please enter a valid email address.',
    // },
};
  
export default texts;