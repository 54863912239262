import './App.css';
import './fonts/fonts.css';
import { Box, Container, CssVarsProvider, extendTheme, Grid, Link, Typography } from '@mui/joy';
import Header from './UI/header/Header';
import Category from './components/Catagory/Category';

import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import SliderBanner from './components/Banners/SliderBanner';
import ItemProduct from './components/ItemProduct/ItemProduct';
import ChipSlider from './components/ChipSlider/ChipSlider';
import MainPage from './pages/MainPage';
import { BrowserRouter, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import CategoryPage from './pages/CategoryPage';
import { AnimatePresence, motion } from "framer-motion";
import { LayoutContext, LayoutProvider } from './context';
import ModalProduct from './components/ModalProduct/ModalProduct';
import ModalAccount from './components/ModalAccount/ModalAccount';
import ModalBasket from './components/ModalBasket/ModalBasket';
import { useContext, useEffect } from 'react';
import ModalOrderCreate from './components/ModalOrderCreate/ModalOrderCreate';
import ModalCompany from './components/ModalCompany/ModalCompany';
import ModalOrders from './components/ModalOrders/ModalOrders';
import ModalLoad from './components/ModalLoad/ModalLoad';
import { APIGetUser } from './api';


const customTheme = extendTheme({
  radius: {
    xs: '8px',  // Мягкие закругления, 8px используется для всех мелких элементов
    sm: '12px', // Закругления для средних элементов
    md: '16px', // Более выраженное закругление для крупных элементов
    xl2: '500px'
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontWeightExtraBold: 800,
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: '#E6F2FC',
          100: '#BFDDF7',
          200: '#99CAF3',
          300: '#66B2EC',
          400: '#3C8CDB',
          500: '#3C8CDB', // Основной цвет бренда
          600: '#3679C3',
          700: '#3167AB',
          800: '#274C82',
          900: '#1E3A66',
          1000: '#3679c36e',
        },
        neutral: {
          50: '#F5F5F5',
          100: '#E0E0E0',
          200: '#BDBDBD',
          300: '#9E9E9E',
          400: '#7E7E7E',
          500: '#616161',
          600: '#424242',
          700: '#303030',
          800: '#212121',
          900: '#181818',
        },
        star: {
          50: '#f5f4ae',
          100: '#dedc37',
        }
      },
      typography: {
        fontFamily: '"Inter", sans-serif',
      },
    },
    dark: {
      palette: {
        primary: {
          50: '#E6F2FC',
          100: '#BFDDF7',
          200: '#99CAF3',
          300: '#66B2EC',
          400: '#3C8CDB',
          500: '#3C8CDB', // Основной цвет бренда
          600: '#3679C3',
          700: '#3167AB',
          800: '#274C82',
          900: '#1E3A66',
          1000: '#3679c36e',
        },
        neutral: {
          50: '#2E2E2E',
          100: '#3A3A3A',
          200: '#4A4A4A',
          300: '#5C5C5C',
          400: '#6E6E6E',
          500: '#7F7F7F',
          600: '#919191',
          700: '#A3A3A3',
          800: '#B5B5B5',
          900: '#D8D8D8',
        },
      },
      typography: {
        fontFamily: '"Inter", sans-serif',
      },
    },
  },
  components: {
    JoyInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          '--Input-focusedInset': 'var(--any, )',
          '--Input-focusedHighlight': 'rgba(13,110,253,.25)',
          '&::before': {
              transition: 'box-shadow .15s ease-in-out',
          },
          '&:focus-within': {
              borderColor: '#86b7fe',
          },
          // borderRadius: theme.radius.sm,
          // fontWeight: theme.fontWeight.xs, // Используем толщину 500 для кнопок
        }),
      },
    },
    JoyButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.radius.sm,
          fontWeight: theme.fontWeight.xs, // Используем толщину 500 для кнопок
        }),
      },
    },
    JoyIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: theme.radius.sm,
          fontWeight: theme.fontWeight.xs, // Используем толщину 500 для кнопок
        }),
      },
    },
    JoyChip: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: theme.radius.xl2,
          '& > button': {
            transition: "all .2s ease-out",
          },
          '& > span': {
            transition: "all .2s ease-out",
          },
          ...(ownerState.variant === 'solid' && ownerState.color === "neutral"  && {
              '& > button': {
                transition: "all .2s ease-out",
                backgroundColor: theme.colorSchemes.light.palette.neutral[700],
              },
              '&:hover > button': {
                backgroundColor: theme.colorSchemes.light.palette.neutral[900]
              },
            }),
          ...(ownerState.variant === 'plain' && ownerState.color === "neutral"  && {
            '& > button': {
              transition: "all .2s ease-out",
              backgroundColor: "white"
            },
            '&:hover > button': {
              backgroundColor: theme.colorSchemes.light.palette.neutral[200]
            },
            '&:hover > span': {
              color: theme.colorSchemes.light.palette.neutral[50]
            }
          }),
        }),
      },
    },
    JoyCard: {
      styleOverrides: {
        root: {
          borderRadius: 'var(--joy-radius-md)',  // Более выраженное закругление для карточек
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});


// -=-=- Анимация -=-=-
function PageWrapper ({ children }) {
  const { modalBasketOpen, modalCompany, modalOrders } = useContext(LayoutContext);

  return (
    <motion.div
      animate={modalBasketOpen | modalCompany | modalOrders ? { scale: 0.9 } : {}}
      transition={{ duration: 0.3 }}
      style={{ position: 'relative', zIndex: 1 }}
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.2 }}
      >
        {children}
      </motion.div>
    </motion.div>
  )
};


// -=-=- Каркас анмиации страниц -=-=-
const AnimatedRoutes = () => {
  const { user, setUser, webApp, setWebApp } = useContext(LayoutContext);
  const location = useLocation();
  
  useEffect(() => {
    console.log('LOADING >>>');
  }, []);

  // -=-=- Загрузка WebApp -=-=-
  useEffect(() => {
    setWebApp(window.Telegram.WebApp);
    // Сообщаем Telegram, что WebApp готово
    webApp?.ready();
    // Разворачиваем WebApp на весь экран
    webApp?.expand();
  }, []);
  // -=-=-=-=-=-=-=-=-=-=-=-

  // Используем useSearchParams для получения параметров из строки запроса
  const [ urlParams ] = useSearchParams();
  // Получаем параметр "token" (например, ?token=rBBuob78...)
  // var token = localStorage.getItem('token');
  // if (!user?.token && urlParams.has('token')) {
  //   console.log("PARAMA", urlParams.get('token'));
  //   console.log("LOCAL", localStorage.getItem('token'));
  //   token = urlParams.get('token');
  //   setUser({...user, token: token});
  //   // userGet();
  //   console.log("USER G", user);
  //   // Сохраняем параметр в localStorage
  //   localStorage.setItem('token', token);
  // }
  // if (token && !user?.token) {
  //   console.log(token);
  //   setUser({...user, token: token});
  //   console.log("USER F", user);
  // }
  // else console.log("USER O", user);


  useEffect(() => {
    const token = localStorage.getItem('token');
    
    if (!user?.token && urlParams.has('token')) {
      const urlToken = urlParams.get('token');
      console.log("PARAMA", urlParams.get('token'));
      console.log("LOCAL", localStorage.getItem('token'));
      // Установить новый токен в user
      setUser((prevUser) => ({ ...prevUser, token: urlToken }));
  
      // Сохранить в localStorage
      localStorage.setItem('token', urlToken);
      
    } else if (token && !user?.token) {
      // Если токен есть в localStorage, но его нет в состоянии
      setUser((prevUser) => ({ ...prevUser, token }));
      console.log("USER F", user);
    }
    else console.log("USER O", user);
  }, [urlParams, user?.token]);
  
  // Отслеживаем изменение user.token
  useEffect(() => {
    if (user?.token) {
      console.log("Token is set in user:", user.token);
    }
  }, [user?.token]);

  return (
    <>
     { user?.token ?
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={
            <PageWrapper>
              <Header pathBag='/'/>
              <MainPage/>
            </PageWrapper>
          } />

          <Route path="/category/:slugID" element={
            <PageWrapper>
              <Header pathBag='/'/>
              <CategoryPage />
            </PageWrapper>
          } />
          <Route path="/category/:slugID" element={
            <PageWrapper>
              <Header pathBag='/'/>
              <CategoryPage />
            </PageWrapper>
          } />
        </Routes>
        <ModalLoad/>
        <ModalProduct/>
        <ModalAccount/>
        <ModalBasket/>
        <ModalOrderCreate/>
        <ModalCompany/>
        <ModalOrders/> 
      </AnimatePresence>
      :
      undefined
     }
    </>
    
  );
};



function App() {
  return (
    <CssVarsProvider theme={customTheme}>
      <LayoutProvider>
        <BrowserRouter>
          <AnimatedRoutes/>
        </BrowserRouter>
      </LayoutProvider>
    </CssVarsProvider>
  );
}

export default App;
