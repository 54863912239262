import React, { useContext, useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// import required modules
import { EffectCoverflow, Autoplay } from 'swiper/modules';
import { Card, CardContent, CardCover, Chip, Divider, Typography } from '@mui/joy';
import { APIGetBanners } from '../../api';
import { LayoutContext } from '../../context';


export default function SliderBanner() {
    const { language } = useContext(LayoutContext);

    const [ banners, setBanners ] = useState(undefined);
    // const banners = [
    //     { id: 1, title: 'Добро пожаловать!', text: 'Мы рады вас видеть в нашем боте', img: 'https://kazautocert.kz/wp-content/webpc-passthru.php?src=https://kazautocert.kz/wp-content/uploads/2021/09/04-09-2021-14-28-51-1024x629.jpg&nocache=1' },
    //     { id: 1, title: 'Закажите из ресторана', text: 'Теперь вы можете заказать прям из ресторана', img: 'https://salon.ru/storage/thumbs/gallery/525/524912/835_3500_s219.jpg' },
    //     { id: 1, title: 'Добро пожаловать!', text: 'Мы рады вас видеть в нашем боте', img: 'https://kazautocert.kz/wp-content/webpc-passthru.php?src=https://kazautocert.kz/wp-content/uploads/2021/09/04-09-2021-14-28-51-1024x629.jpg&nocache=1' }
    // ]

    function GetBanners() {
        APIGetBanners(language).then((data) => {
            if (data) {
                console.log('BANNERS', data);
                setBanners(data);
            }
        })
    }

    useEffect(() => {
        GetBanners();
    }, []);
    useEffect(() => {
        GetBanners();
    }, [language]);

    return (
        <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                stretch: 10,
                slideShadows: false,
            }}
            autoplay={{
                delay: 3000
            }}
            modules={[ EffectCoverflow, Autoplay ]}
            className="mySwiper"
            style={{ margin: '20px 0' }}
        >
            {
                banners ? 
                    banners?.map((item) => ( 
                        <SwiperSlide style={{ width: 'auto' }} key={item.id}>
                            <Card component="li" sx={{ width: '80vw', maxWidth: 400, height: 120, flexGrow: 1, borderRadius: 'sm' }} variant="plain">
                                <CardCover>
                                    <img
                                        src={item.photo}
                                        loading="lazy"
                                        alt=""
                                        style={{ objectFit: 'cover' }}
                                    />
                                </CardCover>
                                <CardCover
                                    sx={{ background: 'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 300px)' }}
                                />
                                <CardContent sx={{ justifyContent: 'flex-end' }}>
                                    <Typography
                                        level="body-lg"
                                        textColor="#fff"
                                        sx={{ fontWeight: 'lg', textAlign: 'left' }}
                                    >
                                        {item.offer}
                                    </Typography>
                                    <Typography
                                        level="body-sm"
                                        textColor="#fff"
                                        sx={{ fontWeight: 'xs', textAlign: 'left' }}
                                    >
                                        {item.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </SwiperSlide>
                    ))
                :
                undefined
            }
        </Swiper>
    )
}