import { Avatar, Box, Button, DialogContent, DialogTitle, Grid, Input, ListDivider, ListItemDecorator, ModalClose, ModalDialog, Option, Select, Stack, Typography } from "@mui/joy";

// import InputMask from 'react-input-mask';
// import ItemOrder from "../ItemOrder";

import CakeIcon from '@mui/icons-material/Cake';
// import { getEmoji } from "../../../utils/utils";
import { useContext, useEffect, useMemo, useState } from "react";
// import { LayoutContext } from "../../context";
// import Cookies from 'js-cookie';


import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { LayoutContext } from "../../../context";
import InputPhone from "../../InputPhone/InputPhone";
import { APIUpdateUser } from "../../../api";



export default function DialogAccount({ state, setOpen, setStage, status }) {
    const { user, setUser, language, setLanguage, textTranslate } = useContext(LayoutContext);

    const [ name, setName ] = useState(user?.name);
    // const [ phone, setPhone ] = useState(user?.phone);
    const [ dateBirth, setDateBirth ] = useState(user?.date_birth);

    useEffect(() => {
        setName(user?.name);
        // setPhone(user?.phone);
        setDateBirth(user?.date_birth);
    }, [user]);

    const initData = window.Telegram.WebApp.initDataUnsafe;

    const options = [
        { value: 'ru', label: 'Русский', src: '/lang/ru.png' },
        { value: 'en', label: 'English', src: '/lang/usa.png' },
        { value: 'si', label: 'සිංහල', src: '/lang/si.png' },
    ];

    // Обработчик изменения языка
    function handleLanguageChange(event, newValue){
        const selectedLanguage = newValue;
        setLanguage(selectedLanguage);
    };

    useEffect(() => {
        const _data = {
            "token": user?.token,
            "lang": language
        }
        APIUpdateUser(_data).then((data) => {
            if (data) {
                setUser({...user, ...data});
            }
        });
    }, [language]);
    


    function elementSelect(option) {
        if (!option) {
          return null;
        }
      
        return (
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={3}
                sx={{ my: 0.5 }}
            >
                <ListItemDecorator>
                    <img width="30px" src={options.find((o) => o.value === option.value)?.src} />
                </ListItemDecorator>
                {option.label}
            </Stack>
        );
    }


    const [ loadSave, setLoadSave ] = useState(false);

    function formatDate(date, format) {
        return format.replace('mm', (date.getMonth() + 1) >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`)
                    .replace('yy', date.getFullYear())
                    .replace('dd', date.getDate());
    }

    // Функция изменения данных Пользователю
    function changeSave() {
        if (name.trim() == '') {
            alert(textTranslate.name_none);
            return
        }
        
        var _data = {
            "token": user?.token,
            "name": name,
        }
        if (dateBirth) _data.date_birth = dateBirth
        setLoadSave(true);
        APIUpdateUser(_data).then((data) => {
            if (data) {
                setLoadSave(false);
                setOpen(false);
                setUser({...user, ...data});
            }
        });
    }

    return (
        <ModalDialog
            variant="plain"
            sx={(theme) => ({
                [theme.breakpoints.up("xl")]: {
                    width: '600px',
                    mx: 0,
                },
                [theme.breakpoints.down("xl")]: {
                    minWidth: '45%',
                    mx: 0,
                },
                [theme.breakpoints.down("lg")]: {
                    width: '60%',
                    mx: 0,
                },
                [theme.breakpoints.down("md")]: {
                    width: '80%',
                    mx: 0,
                },
                [theme.breakpoints.down("sm")]: {
                    width: '90%',
                    p: 3,
                },
                my: 5,
                p: 4,
                borderRadius: 'xl',
                opacity: 0,
                transition: `opacity 200ms`,
                ...{
                    entering: { opacity: 1 },
                    entered: { opacity: 1 },
                }[state],
            })}
        >
            <ModalClose style={{ zIndex: 2 }} />
            <DialogTitle>
                <Typography level="h2">{textTranslate.account}</Typography>
            </DialogTitle>
            <DialogContent>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                    sx={{ px: 1, my: 1 }}
                >
                    <Grid container sx={{ width: '100%' }} spacing={4} columns={{ xs: 4, sm: 2, md: 4 }}>
                        <Grid xs={4} sm="auto" md="auto" lg="auto" sx={{ p: { xs: 0, md: 2 }, width: { sm: '100%', md: 'auto' } }}>
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Avatar
                                    sx={{ p: 2.5, "--Avatar-size": "70px" }}
                                    src='/smile.png'
                                    alt="Аватар" size="lg" />
                                    {/* src={getEmoji(user.phone)} */}
                                {/* <Typography startDecorator={<CakeIcon color="danger"/>}>
                                    У вас {user?.point ? user?.point : 0} бонусов
                                </Typography> */}
                            </Stack>
                            
                        </Grid>
                        <Grid xs={4} sm={2} md sx={{ p: { xs: 0, md: 2 }, mt: { xs: 2, md: 0 } }}>
                            <form>
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                    sx={{ width: '100%' }}
                                >
                                    <Box sx={{ mt: 2, width: '100%' }}>
                                        <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                            {textTranslate.name}*
                                        </Typography>
                                        <Input type="text" onChange={(e) => setName(e.target.value)} value={name} placeholder={textTranslate.ivan} variant="soft" />
                                    </Box>
                                    {/* <Box sx={{ mt: 2, width: '100%' }}>
                                        <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                            {textTranslate.phone}*
                                        </Typography>
                                        <InputPhone phone={phone} setPhone={setPhone}/>
                                        <InputMask mask="+7 (999) 999-99-99" value={phone} onChange={(e) => setPhone(e.target.value)} disabled>
                                            {(inputProps) => <Input {...inputProps} sx={{ borderRadius: 'md', boxShadow: 'none', borderColor: 'neutral.300' }} type="tel" placeholder="+7 (000) 000-00-00" variant="outlined" disableUnderline />}
                                        </InputMask>
                                    </Box> */}
                                    
                                    <Box sx={{ mt: 2, width: '100%' }}>
                                        <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                            {textTranslate.date_birthday}
                                        </Typography>
                                        <Input
                                            slotProps={{
                                                input: {
                                                    max: formatDate(new Date(Date.now()), 'yy-mm-dd'),
                                                },
                                            }}
                                            type="date" variant="soft" value={dateBirth} onChange={(e) => setDateBirth(e.target.value)} />
                                    </Box>

                                    <Box sx={{ mt: 2, width: '100%' }}>
                                        <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                            {textTranslate.language}
                                        </Typography>
                                        <Select
                                            defaultValue={language}
                                            variant="soft"
                                            slotProps={{
                                                listbox: {
                                                    sx: {
                                                        '--ListItemDecorator-size': '44px',
                                                    },
                                                },
                                            }}
                                            sx={{ '--ListItemDecorator-size': '44px', minWidth: 240 }}
                                            renderValue={elementSelect}
                                            onChange={handleLanguageChange}
                                        >
                                            {options.map((option, index) => (
                                                <Box key={index}>
                                                    {index !== 0 ? <ListDivider role="none" inset="startContent" /> : null}
                                                    <Option value={option.value} label={option.label}>
                                                        <ListItemDecorator>
                                                            <img width="30px" src={option.src} />
                                                        </ListItemDecorator>
                                                        {option.label}
                                                    </Option>
                                                </Box>
                                            ))}
                                        </Select>
                                    </Box>
                                </Stack>
                                
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={1}
                                    sx={{ mt: 3 }}
                                >
                                    <Button 
                                        variant="outlined"
                                        sx={{ m: 0, fontSize: 'md' }}
                                        onClick={() => changeSave()}
                                        loading={loadSave}
                                    >
                                        {textTranslate.save}
                                    </Button>
                                </Stack>
                            </form>
                        </Grid>
                    </Grid>
                    
                    {/* { user?.orders & user?.orders != [] ?
                        <Box sx={{ width: '100%' }}>
                            <Typography level="h3" color="primary" sx={{ mb: 2 }}>Заказы</Typography>
                            <Box
                                sx={{ width: '100%', overflowY: 'scroll', height: '40vh' }}
                            >
                                {user?.orders?.map(itm => 
                                    <ItemOrder></ItemOrder>
                                )}
                            </Box>
                        </Box>
                        :
                        undefined
                    } */}
                </Stack>
            </DialogContent>
            {/* {snackBar} */}
        </ModalDialog>
    )
}
