import axios from 'axios';

export const API_URL = 'https://api.biteride.pro'
// export const API_URL = 'http://127.0.0.1:8000'



const API_GET_USER = '/user/get-user/'
// Запрос пользователя
export const APIGetUser = async (token) => {
  try {
    const response = await axios.get(API_URL + API_GET_USER, { 
      params: {
        token: token,  // Передача токена в заголовке
      } 
    });
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return undefined;
  }
};


const API_UPDATE_USER = '/user/update-user-data/'
// Запрос обновление данных пользователя
export const APIUpdateUser = async (data) => {
  try {
    const response = await axios.put(API_URL + API_UPDATE_USER, data);
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return undefined;
  }
};







const API_GET_SHOP_INFO = '/company/get-shop-info/'
// Запрос информацию о магазине 
export const APIGetShopInfo = async (shop_id, lang='ru') => {
  try {
    const response = await axios.get(API_URL + API_GET_SHOP_INFO, { params: { shop_id: shop_id, lang: lang } })
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return undefined;
  }
};



const API_GET_CATEGORIES = '/company/get-categories/'
// Запрос категории
export const APIGetCategories = async (lang='ru') => {
  try {
    const response = await axios.get(API_URL + API_GET_CATEGORIES, { params: { lang: lang } })
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return undefined;
  }
};


const API_GET_PRODUCTS_CATEGORY = '/company/get-products-category/'
// Запрос продуктов по категории
export const APIGetProductsCategory = async (category_slug, lang='ru') => {
  try {
    const response = await axios.get(API_URL + API_GET_PRODUCTS_CATEGORY, { params: { category_slug: category_slug, lang: lang } })
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return undefined;
  }
};


const API_CREATE_ORDER = '/order/create-order/'
// Создание заказа
export const APICreateOrder = async (data) => {
  try {
    const response = await axios.post(API_URL + API_CREATE_ORDER, data)
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return undefined;
  }
};



const API_GET_TYPE_GROUPS_BY_CATEGORY = '/company/type-groups-by-category/'
// Получить типы групп по категории магазина
export const APITypeGroupsByCategory = async (tag_slug, lang='ru') => {
  try {
    const response = await axios.get(API_URL + API_GET_TYPE_GROUPS_BY_CATEGORY, { params: { tag_slug: tag_slug, lang: lang } })
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return undefined;
  }
};


const API_GET_PRODUCTS_GROUP = '/company/get-products-group/'
// Запрос категории
export const APIGetProductsGroup = async ({group_slug = undefined, type_group_slug = undefined, page = 1, limit = 10, lang = 'ru'} = {}) => {
  try {
    var params = {
      page: page, 
      limit: limit,
      lang: lang
    }
    if (group_slug) params.group_slug = group_slug
    if (type_group_slug) params.type_group_slug = type_group_slug

    const response = await axios.get(API_URL + API_GET_PRODUCTS_GROUP, { params: {...params, lang: lang } })
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return undefined;
  }
};




const API_GET_BANNERS = '/system/get-banners/'
// Запрос информацию о магазине 
export const APIGetBanners = async (lang='ru') => {
  try {
    const response = await axios.get(API_URL + API_GET_BANNERS, { params: { lang: lang } })
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return undefined;
  }
};