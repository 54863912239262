import React, { useRef } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// import required modules
import { EffectCoverflow, Autoplay, Pagination } from 'swiper/modules';
import { Card, CardContent, CardCover, Chip, Divider, Typography } from '@mui/joy';
import { API_URL } from '../../api';


export default function SliderDemonstProduct({ photos = [] }) {

    // const banners = [
    //     { id: 1, title: 'Добро пожаловать!', text: 'Мы рады вас видеть в нашем боте', img: 'https://kazautocert.kz/wp-content/webpc-passthru.php?src=https://kazautocert.kz/wp-content/uploads/2021/09/04-09-2021-14-28-51-1024x629.jpg&nocache=1' },
    //     { id: 1, title: 'Закажите из ресторана', text: 'Теперь вы можете заказать прям из ресторана', img: 'https://salon.ru/storage/thumbs/gallery/525/524912/835_3500_s219.jpg' },
    //     { id: 1, title: 'Добро пожаловать!', text: 'Мы рады вас видеть в нашем боте', img: 'https://kazautocert.kz/wp-content/webpc-passthru.php?src=https://kazautocert.kz/wp-content/uploads/2021/09/04-09-2021-14-28-51-1024x629.jpg&nocache=1' }
    // ]
    console.log(photos);
    return (
        <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                stretch: 10,
                slideShadows: false,
            }}
            // autoplay={{
            //     delay: 5000
            // }}
            pagination={{
                dynamicBullets: true,
            }}
            modules={[ EffectCoverflow, Pagination ]}
            className="SwiperProductPhoto"
            style={{
                borderRadius: '20px'
            }}
        >
            { photos.length >= 1 ?
                photos?.map((item) => ( 
                    <SwiperSlide style={{ width: '100%' }} key={item.id}>
                        <Card component="li" sx={{ width: 'auto', minHeight: 220, flexGrow: 1, borderRadius: '20px', borderColor: 'none' }} variant="plain">
                            <CardCover sx={{ borderRadius: '0' }}>
                                <img
                                    src={item.photo}
                                    loading="lazy"
                                    alt=""
                                    style={{ width: '100%', objectFit: 'cover' }}
                                />
                            </CardCover>
                            <CardCover
                                sx={{ background: 'linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0) 300px)' }}
                            />
                        </Card>
                    </SwiperSlide>
                ))
                :
                <SwiperSlide style={{ width: '100%' }}>
                    <Card component="li" sx={{ width: 'auto', minHeight: 220, flexGrow: 1, borderRadius: '20px', borderColor: 'none' }} variant="plain">
                        <CardCover sx={{ borderRadius: '0' }}>
                            <img
                                src={'/default.png'}
                                loading="lazy"
                                alt=""
                                style={{ width: '100%', objectFit: 'cover' }}
                            />
                        </CardCover>
                        <CardCover
                            sx={{ background: 'linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0) 300px)' }}
                        />
                    </Card>
                </SwiperSlide>
            }
        </Swiper>
    )
}